<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import platformConfig from './config/PlatformConfig'

const title = ref(platformConfig.title) 

onMounted(() => {
  document.title = title.value
})

// export default {
//   name: 'App',
//   components: {
//     HelloWorld
//   }
// }
</script>

<style lang="scss">
@import '@/assets/scss/_main.scss';
</style>
