import axios from '@/config/AxiosConfig'
import { notice } from '@/util/notice'
import i18n from '@/plugins/i18n'
import { router } from '@/plugins/router'

const api = {
  async get (url, data, responseType) {
    const res = await axios.get(url, { params: data, responseType: responseType ? responseType : 'json' })
    return new Promise((resolve, reject) => {
      if (res.data.code === 'G_0201' || res.data.code === 'G_0202') {
        notice('error', i18n.global.t(res.data.code) || res.data.code)
        router.push('/login')
      } else if (res.data.code === 'G_0000' || res.status === 200) {
        resolve(res)
      } else {
        reject(res)
      }
    })
  },
  async post (url, data) {
    const res = await axios.post(url, data)
    return new Promise((resolve, reject) => {
      if (res.data.code === 'G_0000') {
        resolve(res)
      } else if (res.data.code === 'G_0201' || res.data.code === 'G_0202') {
        notice('error', i18n.global.t(res.data.code) || res.data.code)
        router.push('/login')
      } else {
        reject(res)
      }
    })
  }
}
export { api }
