import platformConfig from '@/config/PlatformConfig'
const isProduction = process.env.NODE_ENV !== 'development'

const config = {
  options: {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
  },
  baseURL: '',
  routerBase: ''
}

// 要開始打api的話，這裡一定要換
switch (process.env.VUE_APP_TITLE) {
  case ('beforeOnline'): // 幣飛_測試
  case ('coinfare'): // 幣飛_正式
    config.baseURL = `${window.location.protocol}//${window.location.host}/gateway`
    break
  default:
    config.baseURL = platformConfig.baseURL
}
// 要開始打api的話，這裡一定要換

export { config }
