import { notification } from 'ant-design-vue';

export const notice = (type, string) => {
  notification[type]({
    message: string
  })
}
export const notifyReward = (type, title, desc) => {
  notification[type]({
    message: title,
    description: desc
  })
}