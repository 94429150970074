import dayjs from "dayjs"
import i18n from '@/plugins/i18n'

// 處理日期範圍欄位(搜尋欄位-8小時後搜尋)
export function filterDateRange (params, dateRange, startColumn, endColumn) {
  if (dateRange?.length > 0) {
    params[startColumn] = dayjs(dateRange[0]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const endTime = dayjs(dateRange[1]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss').replace('00:00:00', '23:59:59')
    params[endColumn] = endTime
  } else {
    params[startColumn] = ''
    params[endColumn] = ''
  }
  return params
}

// datePicker 快捷鍵選項
export const quickDateRange = {
  [i18n.global.t('date_quick_today')]: [dayjs().startOf('day'), dayjs().endOf('day')],
  [i18n.global.t('date_quick_last_7_day')]: [dayjs().subtract(7, 'day').startOf('day'), dayjs().endOf('day')],
  [i18n.global.t('date_quick_last_30_day')]: [dayjs().subtract(30, 'day').startOf('day'), dayjs().endOf('day')],
  [i18n.global.t('date_quick_this_month')]: [dayjs().startOf('month'), dayjs().endOf('month')],
}

// API返回的時間要+8小時
export const datePlusEight = (date) => {
  const newDate = dayjs(date).add(8, 'hour').format('YYYY-MM-DD HH:mm:ss')
  return newDate
}
