import i18n from '@/plugins/i18n'
import { api } from '@/util/api'

export const localeList = {
  en: 'En',
  tw: 'Tw'
}

// 狀態列表
export const statusList = [
  {
    label: i18n.global.t('enable'),
    value: 'enable'
  },
  {
    label: i18n.global.t('disable'),
    value: 'disable'
  },
]

// 幣種選單
export const coinUnitList = async () => {
  try {
    const { data } = await api.get('/admin/common/c2c/coin')
    return data.data.map(item => {
      return {
        label: item,
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 出款類型選單
export const paymentTypeList = [
  { label: i18n.global.t('paymentType_auto'), value: 'auto' },
  { label: i18n.global.t('paymentType_manual'), value: 'manual' }
]

// 鏈選單
export const chainList = async () => {
  try {
    const { data } = await api.get('/admin/common/chain')
    return data.data.map(item => {
      return {
        label: item,
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 審核狀態
export const reviewStatusList = [
  { label: i18n.global.t('success'), value: 'success' },
  { label: i18n.global.t('pending'), value: 'pending' },
  { label: i18n.global.t('fail'), value: 'fail' }
]

// 劃轉類型
export const swapTypeList = [
  { label: i18n.global.t('swapType_advertise'), value: 'advertise' },
  { label: i18n.global.t('swapType_margin'), value: 'margin' },
  { label: i18n.global.t('swapType_extraMargin'), value: 'extraMargin' }
]

// 訂單類型
export const orderTypeList = [
  { label: i18n.global.t('orderType_deposit'), value: 'deposit' },
  { label: i18n.global.t('orderType_withdraw'), value: 'withdraw' },
  { label: i18n.global.t('orderType_internalTransfer'), value: 'internalTransfer' },
  { label: i18n.global.t('orderType_transfer'), value: 'transfer' },
  { label: i18n.global.t('orderType_buyCoin'), value: 'buyCoin' },
  { label: i18n.global.t('orderType_sellCoin'), value: 'sellCoin' },
  { label: i18n.global.t('orderType_sellCoinAd'), value: 'sellCoinAd' },
  { label: i18n.global.t('orderType_buyCoinReward'), value: 'buyCoinReward' },
  { label: i18n.global.t('orderType_manualAdd'), value: 'manualAdd' },
  { label: i18n.global.t('orderType_manualSub'), value: 'manualSub' },
  { label: i18n.global.t('orderType_tradeReward'), value: 'tradeReward' }
]

// 事件類型
export const eventTypeList = [
  { label: i18n.global.t('eventType_income'), value: 'income' },
  { label: i18n.global.t('eventType_pay'), value: 'pay' },
  { label: i18n.global.t('eventType_freeze'), value: 'freeze' },
  { label: i18n.global.t('eventType_unfreeze'), value: 'unfreeze' },
  { label: i18n.global.t('eventType_spotToC2cMargin'), value: 'spotToC2cMargin' },
  { label: i18n.global.t('eventType_spotToC2c'), value: 'spotToC2c' },
  { label: i18n.global.t('eventType_c2cToSpot'), value: 'c2cToSpot' },
  { label: i18n.global.t('eventType_c2cToExtraMargin'), value: 'c2cToExtraMargin' },
  { label: i18n.global.t('eventType_marginToSpot'), value: 'marginToSpot' },
  { label: i18n.global.t('eventType_cancel'), value: 'cancel' },
  { label: i18n.global.t('eventType_failedRefund'), value: 'failedRefund' }
]

// 錢包類型
export const walletTypeList = [
  { label: i18n.global.t('walletType_spot'), value: 'spot' },
  { label: i18n.global.t('walletType_c2c'), value: 'c2c' }
]

// 廣告類型
export const adTypeList = [
  { label: i18n.global.t('adType_buy'), value: 'buy' },
  { label: i18n.global.t('adType_sell'), value: 'sell' }
]

// 廣告狀態
export const adStatusList = [
  { label: i18n.global.t('adStatus_upShelf'), value: 'upShelf' },
  { label: i18n.global.t('adStatus_downShelf'), value: 'downShelf' },
  { label: i18n.global.t('adStatus_delete'), value: 'delete' }
]

// 廣告下單紀錄-訂單狀態
export const orderStatusList = [
  { label: i18n.global.t('orderStatus_pending'), value: 'pending' },
  { label: i18n.global.t('orderStatus_buyerPaid'), value: 'buyerPaid' },
  { label: i18n.global.t('orderStatus_buyerTimeout'), value: 'buyerTimeout' },
  { label: i18n.global.t('orderStatus_success'), value: 'success' },
  { label: i18n.global.t('orderStatus_fail'), value: 'fail' }
]

// 黑名單選單
export const blackListList = [
  { label: i18n.global.t('blackList_login'), value: 'login' },
  { label: i18n.global.t('blackList_buyCoin'), value: 'buyCoin' },
  { label: i18n.global.t('blackList_sellCoin'), value: 'sellCoin' },
  { label: i18n.global.t('blackList_withdraw'), value: 'withdraw' }
]

// 查詢國家列表
export const getCountryList = async (type) => {
  const params = {
    type: type
  }
  try {
    const { data } = await api.get('/admin/common/country', params)
    return data.data.map(item => {
      return {
        label: i18n.global.t(`country_${item.name}`),
        value: item.name
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 查詢交易法幣類型
export const getCurrencyList = async (type) => {
  const params = {
    type: type
  }
  try {
    const { data } = await api.get('/admin/common/country', params)
    return data.data.map(item => {
      return {
        label: item.currency,
        value: item.currency
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 申訴狀態
export const appealStatusList = [
  { label: i18n.global.t('appealStatus_success'), value: 'success' },
  { label: i18n.global.t('appealStatus_apply'), value: 'apply' },
  { label: i18n.global.t('appealStatus_fail'), value: 'fail' },
  { label: i18n.global.t('appealStatus_receive'), value: 'receive' },
]

// 可新增幣種列表
export const getincreaseCurrencyList = async () => {
  try {
    const { data } = await api.get('/admin/coin/c2c/increase/list')
    return data.data.map(item => {
      return {
        label: item,
        value: item
      }
    })
  } catch (err) {
    console.log('error', err)
  }
}

// 提現訂單狀態
export const withdrawOrderStatusList = [
  { label: i18n.global.t('withdrawOrderStatus_success'), value: 'success' },
  { label: i18n.global.t('withdrawOrderStatus_pending'), value: 'pending' },
  { label: i18n.global.t('withdrawOrderStatus_pass'), value: 'pass' },
  { label: i18n.global.t('withdrawOrderStatus_reject'), value: 'reject' }
]

// 會員身分
export const memberRoleList = [
  { label: i18n.global.t('memberRole_memberGeneral'), value: 'memberGeneral' },
  { label: i18n.global.t('memberRole_memberTrader'), value: 'memberTrader' },
  { label: i18n.global.t('memberRole_agent'), value: 'agent' }
]

// 提現種類
export const withdrawTypeList = [
  { label: i18n.global.t('withdrawType_balance'), value: 'balance' },
  { label: i18n.global.t('withdrawType_margin'), value: 'margin' },
  { label: i18n.global.t('withdrawType_profitRebate'), value: 'profitRebate' }
]

// 法定代幣與國家對應表
export const currencyMappingList = {
  "TWD": i18n.global.t('country_Taiwan'),
  "USD": i18n.global.t('country_America')
}

// 查詢VIP等級列表
export const getVipLevelList = async (isContainZero = false) => {
  try {
    let { data } = await api.get('admin/system/vipSetting/query')
    data = data.data.map(item => {
      return {
        label: item.level,
        value: item.level
      }
    })
    if (isContainZero) {
      data.unshift({
        label: 0,
        value: 0
      })
    }
    return data
  } catch (err) {
    console.log('error', err)
  }
}

// 會員類型
export const memberTypeList = [
  { label: i18n.global.t('memberRole_memberGeneral'), value: 'general' },
  { label: i18n.global.t('memberRole_memberTrader'), value: 'trader' },
]

// KYC認證等級
export const kycLevelList = [
  { label: i18n.global.t('kycLevel_newRegister'), value: 'newRegister' },
  { label: i18n.global.t('kycLevel_general'), value: 'general' },
  { label: i18n.global.t('kycLevel_advanced'), value: 'advanced' },
]

// 商家申請資格
export const traderStatusList = [
  { label: i18n.global.t('traderStatus_generalMember'), value: 'generalMember' },
  { label: i18n.global.t('traderStatus_notApply'), value: 'notApply' },
  { label: i18n.global.t('traderStatus_applied'), value: 'applied' },
  { label: i18n.global.t('traderStatus_reject'), value: 'reject' },
  { label: i18n.global.t('traderStatus_pass'), value: 'pass' },
]

// 會員狀態
export const memberStatusList = [
  { label: i18n.global.t('memberStatus_enable'), value: 'enable' },
  { label: i18n.global.t('memberStatus_disable'), value: 'disable' },
  { label: i18n.global.t('memberStatus_delete'), value: 'delete' }
]

// 掛單狀態
export const pendingOrderStatusList = [
  { label: i18n.global.t('pendingOrderStatus_upShelf'), value: 'upShelf' },
  { label: i18n.global.t('pendingOrderStatus_downShelf'), value: 'downShelf' },
]


// 證件類型
export const identityTypeList = [
  { label: i18n.global.t('identityType_drivingLicence'), value: 'drivingLicence' },
  { label: i18n.global.t('identityType_idCard'), value: 'idCard' },
  { label: i18n.global.t('identityType_passport'), value: 'passport' }
]

// KYC審核狀態
export const kycReviewStatusList = [
  { label: i18n.global.t('kycReviewStatus_success'), value: 'success' },
  { label: i18n.global.t('kycReviewStatus_pending'), value: 'pending' },
  { label: i18n.global.t('kycReviewStatus_fail'), value: 'fail' }
]

// 買賣幣掛單狀態
export const coinStatusList = [
  { label: i18n.global.t('coinStatus_true'), value: 'true' },
  { label: i18n.global.t('coinStatus_false'), value: 'false' },
]

// 會員來源列表
export const getMemberSourceTypeList = async (type = 'all') => {
  try {
    const { data } = await api.get('admin/common/memberSourceTypeList', { type })
    return data.data.map(({ id, name }) => ({ value: id, label: name ? i18n.global.t(`casino`) + '-' + name : i18n.global.t(`member_source_type-${id}`) }))
  } catch (err) {
    console.log('error', err)
  }
}