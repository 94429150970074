import { JSEncrypt } from 'encryptlong' // ! RSA加密
import { Base64 } from 'js-base64' // ! base64加密
// ! 拿到的RSA 公鑰 請先手動轉成base64 讓網頁build後的 js找不到rsa公鑰
export var baseSixFour = ''
export var secretKey = ''
export const SetSixFour = (value) => {
  baseSixFour = value
}
export const SetSecretKey = (value) => {
  secretKey = value
}
/** 加密
 * @param {object || string} data - 待加密的參數
 * @param {string} method - get or post
 * 流程為 拿 baseSixFour 做Base64 解密 > RSA加密 > get的話就 encodeURI post 則不用
 */
export const encryptedData = async (data, method) => {
  const encryptor = new JSEncrypt() // 新建JSEncrypt对象
  const decode = await Base64.decode(baseSixFour) // 解密base64 Key
  await encryptor.setPublicKey(decode) // 設置公鑰

  if (method === 'get') {
    const base64Data = data // 轉base64
    const afterRsa = await encodeURIComponent(encryptor.encryptLong(base64Data)) // 加密長的數據
    return afterRsa
  } else {
    // const JSONData = JSON.stringify(data) // 轉json字串
    const base64Data = btoa(data) // 轉base64
    const encryptorTemp = await encryptor.encryptLong(base64Data) // 加密長的數據
    return encryptorTemp
  }
}

// 將 物件 轉換成 binary(byte陣列)
export const objectToBinary = (obj) => {
  // 將物件轉換成 JSON 字串
  const jsonStr = JSON.stringify(obj);

  // 將 JSON 字串轉成 binary(byte陣列)
  const encoder = new TextEncoder();

  return encoder.encode(jsonStr)
}
