import { createRouter, createWebHashHistory } from 'vue-router'

export const mainRoutes = {
  name: 'Main',
  component: () => import(/* webpackChunkName: "main" */ '@/views/Main'),
  children: [
    // 首頁
    { path: '/index', component: () => import(/* webpackChunkName: "Index" */ '@/views/Index/Index'), name: 'index' },
    { path: '/index-user-center', component: () => import(/* webpackChunkName: "IndexUserCenter" */ '@/views/Index/IndexUserCenter'), name: 'index-user-center' },
    { path: '/index-platform-data', component: () => import(/* webpackChunkName: "IndexPlatformData" */ '@/views/Index/IndexPlatformData'), name: 'index-platform-data' },
    // 管理員管理
    { path: '/staff-list', component: () => import(/* webpackChunkName: "StaffList" */ '@/views/Staff/StaffList'), name: 'staff-list' },
    // 系統管理
    { path: '/system-c2c-setting', component: () => import(/* webpackChunkName: "SystemC2cSetting" */ '@/views/System/SystemC2cSetting'), name: 'system-c2c-setting' },
    { path: '/system-super-pay-setting', component: () => import(/* webpackChunkName: "SuperPaySetting" */ '@/views/System/SuperPaySetting'), name: 'system-superPay-setting' },
    { path: '/system-chinese-bank-list', component: () => import(/* webpackChunkName: "ChineseBankList" */ '@/views/System/ChineseBankList'), name: 'system-chinese-bank-setting' },
    { path: '/system-entCity', component: () => import(/* webpackChunkName: "CasinoList" */ '@/views/System/CasinoList'), name: 'system-entCity' },
    { path: '/system-special-member', component: () => import(/* webpackChunkName: "SpecialMember" */ '@/views/System/SpecialMember'), name: 'system-special-member' },
    { path: '/system-vip-setting', component: () => import(/* webpackChunkName: "systemVipSetting" */ '@/views/System/SystemVipSetting'), name: 'system-vip-setting' },
    { path: '/system-blacklist', component: () => import(/* webpackChunkName: "blackList" */ '@/views/System/BlackList'), name: 'system-blacklist' },
    { path: '/system-country', component: () => import(/* webpackChunkName: "SystemCountry" */ '@/views/System/SystemCountry'), name: 'system-country' },
    { path: '/system-app-version', component: () => import(/* webpackChunkName: "SystemAppVersion" */ '@/views/System/SystemAppVersion'), name: 'system-app-version' },
    { path: '/system-api-errorLog', component: () => import(/* webpackChunkName: "APIErrorLog" */ '@/views/System/APIErrorLog'), name: 'system-api-errorLog' },
    // 會員管理
    { path: '/member-list', component: () => import(/* webpackChunkName: "MemberList" */ '@/views/Member/MemberList'), name: 'member-list' },
    { path: '/member-kyc', component: () => import(/* webpackChunkName: "memberKyc" */ '@/views/Member/MemberKyc'), name: 'member-kyc' },
    { path: '/member-deposit', component: () => import(/* webpackChunkName: "memberDeposit" */ '@/views/Member/MemberDeposit'), name: 'member-deposit' },
    { path: '/member-swap', component: () => import(/* webpackChunkName: "memberSwap" */ '@/views/Member/MemberSwap'), name: 'member-swap' },
    { path: '/member-withdraw', component: () => import(/* webpackChunkName: "memberWithdraw" */ '@/views/Member/MemberWithdraw'), name: 'member-withdraw' },
    { path: '/member-ledger', component: () => import(/* webpackChunkName: "memberLedger" */ '@/views/Member/MemberLedger'), name: 'member-ledger' },
    // 商家管理
    { path: '/business-advertise', component: () => import(/* webpackChunkName: "businessAdvertise" */ '@/views/Business/BusinessAdvertise'), name: 'business-advertise' },    
    { path: '/business-coinMerchant', component: () => import(/* webpackChunkName: "businessCoinMerchant" */ '@/views/Business/BusinessCoinMerchant'), name: 'business-coinMerchant' },    
    // 訂單管理
    { path: '/order-list', component: () => import(/* webpackChunkName: "orderList" */ '@/views/Order/OrderList'), name: 'order-list' },
    { path: '/order-appeal', component: () => import(/* webpackChunkName: "orderAppeal" */ '@/views/Order/OrderAppeal'), name: 'order-appeal' },
    // 幣種管理
    { path: '/coin-chain', component: () => import(/* webpackChunkName: "CoinChain" */ '@/views/Coin/CoinChain'), name: 'coin-chain' },
    { path: '/coin-c2c', component: () => import(/* webpackChunkName: "CoinC2C" */ '@/views/Coin/CoinC2C'), name: 'coin-c2c' },
    // 財務管理
    { path: '/finance-funds', component: () => import(/* webpackChunkName: "financeFunds" */ '@/views/Finance/FinanceFunds'), name: 'finance-funds' }
  ],
  meta: {
    requiresAuth: true
  }
}

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  mainRoutes
]

export const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(), // 使用 hash 模式
  routes
})

// Middleware
router.beforeEach((to, from, next) => {
  const isAuthenticated = sessionStorage.getItem('token')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})
