/**
 * 權限管理
 * @param {string} parentId // 父層id
 * @param {string} childId // 要尋找的子層id
 * @returns
 */
export function authsHandler (parentId, childId) {
  const authorityList = JSON.parse(sessionStorage.getItem('authorityList'))
  const manageNode = authorityList.filter(item => item.id === parentId)[0]
  return getChildNode(manageNode, childId)
}

// 權限遞迴，尋找node children中的id相同的資料
export function getChildNode (node, id) {
  let resultChild = ''
  node.children.forEach((childNode) => {
    if (childNode.id === id) {
      resultChild = childNode
    } else {
      const result = getChildNode(childNode, id)
      if (result) {
        resultChild = result
      }
    }
  })
  return resultChild
}
