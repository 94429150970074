let platformConfig = {}
// 測試環境
const beforeOnlineConfig = {
  appId: 'dev-id',
  title: 'CoinFare',
  baseURL: 'https://devhew1irgauy3.coinfare.net/gateway',
  webSocketUrl: 'wss://devhew1irgauy3.coinfare.net/gateway/websocket/connect'
}
// 正式環境
const coinfareConfig = {
  appId: 'dev-id',
  title: 'CoinFare',
  baseURL: 'https://hew1irgauy3.coinfare.net/gateway',
  webSocketUrl: 'wss://hew1irgauy3.coinfare.net/gateway/websocket/connect'
}
switch (process.env.VUE_APP_TITLE) {
  case 'coinfare': // 幣飛正式
    platformConfig = coinfareConfig
    break
  case 'beforeOnline': // 幣飛測試
    platformConfig = beforeOnlineConfig
    break
  default:
    platformConfig = beforeOnlineConfig
}
export default platformConfig
