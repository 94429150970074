import axios from 'axios'
import { config } from '@/config/config'
import { secretKey } from '@/util/encrypt'
// import store from '@/store'
axios.defaults.baseURL = config.baseURL
axios.interceptors.request.use(config => {
  config.headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-header': '*',
    'Time-Zone': 'Asia/Taipei',
    'api-secret-key': secretKey    // 主要是追加這個
    // authorization: store.state.login.token
  }
  const token = sessionStorage.getItem('token') || ''
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }
  return config
}, err => {
  return Promise.reject(err)
})

export default axios